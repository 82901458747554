import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const styles = theme => ({
  root: {
    padding: "10px 40px",
    color: "white",
    border: "1.8px solid white",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "#fafafaAA",
      border: "1.5px solid #fafafaAA",
    },
  },
});
export default withStyles(styles)(Button);
